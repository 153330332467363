import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"

const Wrapper = styled.div`
  margin: 80px auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0;
`

const ChooseAgentCard = styled.div`
  min-height: 340px;
  width: 470px;
  border: 1px solid rgba(151, 151, 151, 0.18);
  padding: 40px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

const ChooseAgents = () => (
  <Wrapper>
    <Heading type="h2" color="#11adf3">
      Why choose our Agents?
    </Heading>
    <ContentWrapper>
      <ChooseAgentCard>
        <img src="//d2fedz0by71ckz.cloudfront.net/images/1-top.png" alt="performers" />
        <Heading type="h3" color="#11adf3">
          Top performers in your market
        </Heading>
        <Paragraph align="center" size="16">
          Hand selected agents from the top 15% in your market based on sales volume.
        </Paragraph>
      </ChooseAgentCard>
      <ChooseAgentCard>
        <img src="//d2fedz0by71ckz.cloudfront.net/images/2-connected.png" alt="performers" />
        <Heading type="h3" color="#11adf3">
          Access to exclusive listings
        </Heading>
        <Paragraph align="center" size="16">
          Well-connected & equipped to find you the best deals, including off-market opportunities.
        </Paragraph>
      </ChooseAgentCard>
      <ChooseAgentCard>
        <img src="//d2fedz0by71ckz.cloudfront.net/images/3-invocie.png" alt="performers" />
        <Heading type="h3" color="#11adf3">
          Committed to customer service
        </Heading>
        <Paragraph align="center" size="16">
          Our agents value client satisfaction above everything else & will always be available to you.
        </Paragraph>
      </ChooseAgentCard>
      <ChooseAgentCard>
        <img src="//d2fedz0by71ckz.cloudfront.net/images/4-techie.png" alt="performers" />
        <Heading type="h3" color="#11adf3">
          Tech forward
        </Heading>
        <Paragraph align="center" size="16">
          Experts in using powerful digital channels to market your needs or property & close the deal faster.
        </Paragraph>
      </ChooseAgentCard>
    </ContentWrapper>
  </Wrapper>
)

export default ChooseAgents
