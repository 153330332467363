import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import BlockQuote from "dumbComponents/common/UI/BlockQuote"

const Wrapper = styled.div`
  margin: 80px auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 40px 0;
`

const AgentCard = styled.div`
  width: 300px;
  text-align: center;
`

const AgentImage = styled.img`
  height: 300px;
  width: 300px;
`

const ImageContentWrapper = styled.div`
  background-color: #fff;
  height: 250px;
  width: 300px;
  margin: 0 auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
`

const Content = styled.div`
  padding: 15px 35px 0 20px;
`

export const TopAgents = () => (
  <Wrapper>
    <Heading type="h2" color="#11adf3">
      Agents we work with
    </Heading>
    <ContentWrapper>
      <AgentCard>
        <AgentImage src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/Mary-Olivia.png" alt="Oliva" />
        <ImageContentWrapper>
          <Content>
            <Paragraph align="center" fontWeight="500">
              Olivia & Mary from Burlingame, CA
            </Paragraph>
            <Paragraph align="center" size="16" m="15px 20px">
              <BlockQuote>
                Mary and Olivia helped us find our dream home at a great value in Burlingame. We put in an offer before
                it even hit the market and struck a deal.
              </BlockQuote>
            </Paragraph>
          </Content>
        </ImageContentWrapper>
      </AgentCard>
      <AgentCard>
        <AgentImage src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/dale.png" alt="Dale" />
        <ImageContentWrapper>
          <Content>
            <Paragraph align="center" fontWeight="500">
              Dale from Raleigh, NC
            </Paragraph>
            <Paragraph align="center" size="16" m="15px 20px">
              <BlockQuote>
                We were first-time homebuyers and moving from across the country! Dale walked us through the process
                from day one and was always responsive, knowledgeable, patient and helpful.
              </BlockQuote>
            </Paragraph>
          </Content>
        </ImageContentWrapper>
      </AgentCard>
      <AgentCard>
        <AgentImage src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/jen.png" alt="Jen" />
        <ImageContentWrapper>
          <Content>
            <Paragraph align="center" size="20" fontWeight="500">
              Jen from Baltimore, MD
            </Paragraph>
            <Paragraph align="left" size="16" m="15px 20px">
              <BlockQuote>
                My husband and I were super lucky to buy and sell our first home with Jen. Our house was under contract
                in 4 days thanks to her stellar marketing skills.
              </BlockQuote>
            </Paragraph>
          </Content>
        </ImageContentWrapper>
      </AgentCard>
    </ContentWrapper>
  </Wrapper>
)

export default TopAgents
