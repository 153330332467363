import React from "react"
import styled from "styled-components"

const StyledBlockquote = styled.blockquote`
  display: inline-block;
  font-family: "Rubik";
  font-size: 14px;
  font-style: italic;
  line-height: 1.71;
  position: relative;
  border-left: none;
  color: #354052;

  &:before {
    content: "\\201C";
    display: block;
    padding-left: 10px;
    font-family: "Carrara-bol";
    line-height: 1.35;
    font-size: 100px;
    position: absolute;
    left: -35px;
    top: -15px;
    color: #bfe7f9;
    font-size: 50px;
  }
`

const BlockQuote = ({ children }) => <StyledBlockquote>{children}</StyledBlockquote>

export default BlockQuote
