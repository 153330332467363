import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import { MOBILE_BREAK_POINT } from "shared/consts"
import Container from "container/Home"
import SideBar from "./SideBar"

const Wrapper = styled.div`
  background: url(//d2fedz0by71ckz.cloudfront.net/images/radius-homes-hero.jpg) no-repeat 50%;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
`

const SelectableBox = styled.div`
  width: 375px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  text-align: center;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #11adf3;
    color: #ffffff;
    cursor: pointer;
    p {
      color: #ffffff;
    }
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 250px;
  }
`

const StyledParagraph = styled.p`
  color: #11adf3;
  margin-bottom: 0;
`

const LookingToSpend = (props) => {
  const {
    saveSpend,
    history: { push },
    match: {
      params: { goal },
    },
    client,
    requirement,
    buyingLocation,
    sellerLocation,
    estimateText,
    timelineDisplay,
  } = props
  const spendingMoney = (min, max, textToDisplay) => {
    saveSpend({ min_budget: min, max_budget: max, textToDisplay })
    push(`/ads/${goal}/timeline`)
  }
  return (
    <Wrapper>
      <Layout>
        <SideBar
          activePage={3}
          client={client}
          requirement={requirement}
          buyingLocation={buyingLocation}
          sellerLocation={sellerLocation}
          estimateText={estimateText}
          timelineDisplay={timelineDisplay}
          goal={goal}
        />
        <Content>
          <Heading margin="40px auto">How much are you looking to spend?</Heading>
          <SelectableBox onClick={() => spendingMoney(0, 250000, "Up to $250k")}>
            <StyledParagraph color="#11adf3">Up to $250k</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => spendingMoney(250000, 500000, "$250k - $500k")}>
            <StyledParagraph color="#11adf3">$250k - $500k</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => spendingMoney(500000, 1000000, "$500k - $1m")}>
            <StyledParagraph color="#11adf3">$500k - $1m</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => spendingMoney(1000000, 2500000, "$1m - $2.5m")}>
            <StyledParagraph color="#11adf3">$1m - $2.5m</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => spendingMoney(2500000, 5500000, "$2.5m - $5.5m")}>
            <StyledParagraph color="#11adf3">$2.5m - $5.5m</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => spendingMoney(5500000, 6000000, "$5.5m or more")}>
            <StyledParagraph color="#11adf3">$5.5m or more</StyledParagraph>
          </SelectableBox>
        </Content>
      </Layout>
    </Wrapper>
  )
}

export default Container(LookingToSpend)
