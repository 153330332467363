import React from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import CheckMarkSvg from "images/svg/check-mark.svg"
import Container from "container/Home"
import { MOBILE_BREAK_POINT } from "shared/consts"
import SideBar from "./SideBar"

const Wrapper = styled.div`
  background: url(//d2fedz0by71ckz.cloudfront.net/images/radius-homes-hero.jpg) no-repeat 50%;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    position: inherit;
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
`

const SelectableBox = styled.div`
  width: 375px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  text-align: center;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const StyledParagraph = styled.p`
  color: #11adf3;
  margin-bottom: 0;
`

const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 600px;
  margin-top: 15px;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: auto;
  }
`

const ImageWrapper = styled.div`
  position: relative;
`

const PropertyTypeImage = styled.img`
  height: 175px;
  margin-bottom: 15px;
  transition: transform 0.5s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    height: 130px;
  }
`

const CheckMark = styled.img`
  position: absolute;
  right: 10px;
  bottom: 35px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledHeading = styled(Heading)`
  margin: 40px auto;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    margin: 30px 0 0 0;
  }
`

class PropertyTypes extends React.Component {
  constructor(props) {
    super(props)

    const {
      match: {
        params: { goal },
      },
      requirement: { types },
    } = this.props
    this.state = {
      types,
    }
  }

  changeTypes = (selectedType) => {
    const { types } = this.state
    console.log(this.props)
    const {
      history: { push },
      match: {
        params: { goal },
      },
      savePropertyTypes,
      saveSellerPropertyTypes,
    } = this.props
    if (goal !== "sell") {
      const index = types.indexOf(selectedType)
      if (index === -1) {
        this.setState({ types: types.concat(selectedType) }, () => {
          savePropertyTypes({ types })
        })
      } else {
        this.setState({ types: types.filter(type => selectedType !== type) }, () => {
          savePropertyTypes({ types })
        })
      }
    } else {
      this.setState({ types: [selectedType] }, () => {
        saveSellerPropertyTypes({ property_type: selectedType })
      })
    }
  }

  goNext = () => {
    const {
      history: { push },
      match: {
        params: { goal },
      },
      savePropertyTypes,
      saveSellerPropertyTypes,
    } = this.props
    const { types } = this.state
    if (goal === "sell") {
      saveSellerPropertyTypes({ property_type: types })
      push(`/ads/${goal}/timeline`)
    } else {
      savePropertyTypes({ types })
      push(`/ads/${goal}/looking-to-spend`)
    }
  }

  render() {
    const { types } = this.state
    const {
      match: {
        params: { goal },
      },
      client,
      requirement,
      buyingLocation,
      sellerLocation,
      estimateText,
      timelineDisplay,
    } = this.props
    return (
      <Wrapper>
        <Layout>
          <SideBar
            activePage={2}
            client={client}
            requirement={requirement}
            buyingLocation={buyingLocation}
            sellerLocation={sellerLocation}
            estimateText={estimateText}
            timelineDisplay={timelineDisplay}
            goal={goal}
          />
          <Content>
            {goal !== "sell" ? (
              <StyledHeading>What kind of home are you looking for?</StyledHeading>
            ) : (
              <StyledHeading>What kind of home are you looking to sell?</StyledHeading>
            )}
            <TypeContainer>
              <ImageWrapper onClick={() => this.changeTypes("Single-family home")}>
                <PropertyTypeImage src="//d2fedz0by71ckz.cloudfront.net/images/single-fam@2x.png" />
                {types.indexOf("Single-family home") !== -1 && <CheckMark src={CheckMarkSvg} />}
              </ImageWrapper>
              <ImageWrapper onClick={() => this.changeTypes("Condominium")}>
                <PropertyTypeImage src="//d2fedz0by71ckz.cloudfront.net/images/condo@2x.png" />
                {types.indexOf("Condominium") !== -1 && <CheckMark src={CheckMarkSvg} />}
              </ImageWrapper>
              <ImageWrapper onClick={() => this.changeTypes("Townhouse")}>
                <PropertyTypeImage src="//d2fedz0by71ckz.cloudfront.net/images/thouse@2x.png" />
                {types.indexOf("Townhouse") !== -1 && <CheckMark src={CheckMarkSvg} />}
              </ImageWrapper>
              <ImageWrapper onClick={() => this.changeTypes("Manufactured/Mobile")}>
                <PropertyTypeImage src="//d2fedz0by71ckz.cloudfront.net/images/manufacture@2x.png" />
                {types.indexOf("Manufactured/Mobile") !== -1 && <CheckMark src={CheckMarkSvg} />}
              </ImageWrapper>
              <ImageWrapper onClick={() => this.changeTypes("Lot/Land")}>
                <PropertyTypeImage src="//d2fedz0by71ckz.cloudfront.net/images/vacant@2x.png" />
                {types.indexOf("Lot/Land") !== -1 && <CheckMark src={CheckMarkSvg} />}
              </ImageWrapper>
              <ImageWrapper onClick={() => this.changeTypes("Commercial")}>
                <PropertyTypeImage src="//d2fedz0by71ckz.cloudfront.net/images/commer@2x.png" />
                {types.indexOf("Commercial") !== -1 && <CheckMark src={CheckMarkSvg} />}
              </ImageWrapper>
            </TypeContainer>
            <ButtonContainer>
              <Button onClick={this.goNext}>Next</Button>
            </ButtonContainer>
          </Content>
        </Layout>
      </Wrapper>
    )
  }
}

export default Container(PropertyTypes)
