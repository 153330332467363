import styled from "styled-components"
import { MOBILE_BREAK_POINT } from "shared/consts"
const headingTypes = {
  h1: "43px",
  h2: "36px",
  h3: "30px",
  h4: "24px",
  h5: "20px",
  h6: "16px",
}

const Heading = styled.h1`
  font-family: ${props => `${props.font ? props.font : "carrara-bol, Rubik"}, sans-serif`};
  font-size: ${props => (props.size ? props.size : headingTypes[props.type])};
  font-weight: ${props => (props.weight ? props.weight : "500")};
  font-style: normal;
  font-stretch: normal;
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1)};
  letter-spacing: ${props => props.spacing};
  text-align: ${props => props.align};
  color: ${props => (props.color ? props.color : "#354052")};
  margin: ${props => (props.margin ? props.margin : "auto")};
  display: ${props => props.display};
  width: ${props => (props.w ? props.w : "auto")};
  animation: fade-up 0.4s ease-out forwards;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    font-size: 36px;
  }
  @keyframes fade-up {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

Heading.defaultProps = {
  type: "h1",
  align: "center",
  color: "#354052",
  lineHeight: 1.28,
  font: "carrara-bol",
  spacing: "normal",
  display: "block",
}

export default Heading
