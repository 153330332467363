const colors = {
  primaryColor: "#11adf3",
  primaryHover: "#0088a6",
  disabledColor: "#e7ebed",
  tabDisabledColor: "#c4c7ca",
  infoBorderColor: "#10adf3",
  white: "#ffffff",
  borderColor: "#e7e8ea",
  primaryBorderColor: "#11adf3",
  starInactive: "#d4d4d4",
  starActive: "#ffd05b",
}

export default colors
