import React, { Component } from "react"
import styled from "styled-components"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import IconInput from "dumbComponents/common/UI/IconInput"
import icons from "library/icons/icons"
import colors from "library/colors"
import poweredByGooglePNG from "images/poweredByGoogle.png"
import { MOBILE_BREAK_POINT } from "shared/consts"

const searchOptions = {
  types: ["(cities)"],
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 0;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    margin-bottom: 15px;
  }
`

const SuggestionsWrapper = styled.div`
  background-color: white;
  position: absolute;
  top: 40px;
  cursor: pointer;
  border: 1px solid ${colors.borderColor};
  width: 350px;
  z-index: 1;
`

const Suggestion = styled.div`
  background-color: white;
  color: ${colors.tagInactive};
  font-weight: 300;
  border-top: 1px solid ${colors.borderColor};

  padding: 8px;
  ${props => props.active
    && `
    background-color: ${colors.greyBackground};
  `}
`
const PoweredByGoogle = styled.div`
  cursor: not-allowed;
  padding: 8px;
  position: relative;
`

const GoogleImage = styled.img`
  position: absolute;
  bottom: 4px;
  right: 0;
`

export default class GooglePlaces extends Component {
  handleSelect = (address, placeId) => {
    const { onLocationSelect, rawTextSearch, handleSubmit } = this.props
    if (address) {
      if (!placeId) {
        rawTextSearch(address)
      } else {
        geocodeByAddress(address)
          .then((results) => {
            const {
              address_components,
              geometry: { location },
            } = results[0]
            const loc = {}
            address_components.forEach((component) => {
              const { types } = component
              if (types[0] === "locality") {
                loc.city = component.long_name || null
              } else if (types[0] === "administrative_area_level_1") {
                loc.state = component.long_name || null
              } else if (types[0] === "country") {
                loc.country = component.long_name || null
              }
            })

            loc.lat = location.lat()
            loc.lng = location.lng()
            onLocationSelect(loc)
          })
          .catch(error => console.error("Google Places Error", error))
      }
    } else {
      handleSubmit()
    }
  }

  handleError = (status, clearSuggestions) => {
    //console.log("Google Maps API returned error with status: ", status)
    clearSuggestions()
  }

  makeActive = (id) => {
    console.log(id)
    document.getElementById(id).style.border = "1px solid #11adf3"
  }

  makeInActive = (id) => {
    document.getElementById(id).style.border = "1px solid #e7e8ea"
  }

  render() {
    const {
      forwardedRef, addressTextBoxValue, handleAddressTextBoxChange, handleOnBlur, placeholder, id,
    } = this.props

    return (
      <PlacesAutocomplete
        value={addressTextBoxValue}
        onChange={handleAddressTextBoxChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        onError={this.handleError}
        highlightFirstSuggestion
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <Wrapper>
            <IconInput id={id} iconClassName={icons.locationLocationPoint} width="240px">
              <input
                {...getInputProps({
                  placeholder: placeholder || "Search for city",
                  className: "location-search-input",
                  onBlur: handleOnBlur,
                  ref: forwardedRef,
                })}
                style={{ height: "33px" }}
                onFocus={() => this.makeActive(id)}
                onBlur={() => this.makeInActive(id)}
              />
            </IconInput>

            {loading
              || (suggestions.length > 0 && (
                <SuggestionsWrapper>
                  {loading && <Suggestion>Loading...</Suggestion>}
                  {suggestions.map(suggestion => (
                    <Suggestion {...getSuggestionItemProps(suggestion)} active={suggestion.active}>
                      <span>{suggestion.description}</span>
                    </Suggestion>
                  ))}
                  <PoweredByGoogle>
                    <GoogleImage src={poweredByGooglePNG} height={14} />
                  </PoweredByGoogle>
                </SuggestionsWrapper>
              ))}
          </Wrapper>
        )}
      </PlacesAutocomplete>
    )
  }
}
