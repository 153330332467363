import {
  takeLatest, call, put, select,
} from "redux-saga/effects"
import { get } from "lodash"
import { addClientAPI, addBuyerNeedsAPI } from "./api"
import { submitLeadAction } from "./actions"

function* submitLead(action) {
  try {
    const {
      payload,
      push,
      payload: {
        agent_requirements: { goal },
      },
    } = action.data
    payload.agent_requirements = JSON.stringify(payload.agent_requirements)
    const res = yield call(addClientAPI, payload)
    const requirement = yield select(state => state.homes.requirement)
    if (res.data) {
      if (goal !== "sell") {
        const {
          response: { id },
        } = res.data
        const reqPayload = { ...requirement }
        reqPayload.u_cust_id = id
        reqPayload.sale_type = "resale"
        const reqRes = yield call(addBuyerNeedsAPI, reqPayload)
      }

      yield put(submitLeadAction.success())
      window.gtag("event", "conversion", { send_to: "AW-832391564/DzFGCNDm35QBEIyT9YwD" })
      push("/ads/success")
    }
  } catch (e) {
    console.log(e)
    const { push } = action.data
    const {
      response: { status },
    } = e
    if (status === 400) {
      const errorMessage = get(e, "response.data.error.message")
      yield put(submitLeadAction.failure({ errorMessage }))
    } else {
      yield put(
        submitLeadAction.failure({
          errorMessage: "Something went wrong. Please try with alternate email or phone number.",
        })
      )
    }
    window.gtag("event", "conversion_failed", { send_to: "AW-832391564/DzFGCNDm35QBEIyT9YwD" })
  }
}

export default function* main() {
  yield takeLatest(submitLeadAction.REQUEST, submitLead)
}
