import React from "react"
import styled from "styled-components"
import colors from "library/colors"
import { MOBILE_BREAK_POINT } from "shared/consts"

const LocationWrapper = styled.div`
  background-color: #f8f9fa;
  border: 1px solid ${colors.borderColor};
  color: ${colors.tagInactive};
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: ${props => props.width};
  height: 100%;

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: auto;
  }

  & > input {
    outline: none;
    background-color: inherit;
    border: none;
    width: 100%;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: inherit;
      font-weight: inherit;
    }
  }
`

const LocationIcon = styled.i`
  color: ${colors.primaryInactiveColor};
  padding: 0 4px;
  margin: 0 4px;
`

const IconInput = ({
  iconClassName, children, width, className, style, id,
}) => (
  <LocationWrapper width={width} className={className} style={style} id={id}>
    <LocationIcon className={iconClassName} />
    {children}
  </LocationWrapper>
)

IconInput.defaultProps = {
  width: "200px",
}

export default IconInput
