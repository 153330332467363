import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"

const Wrapper = styled.div`
  margin: 80px auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0;
`

const QuestionSymbol = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 3px;
  background-color: #419ef7;
  display: flex;
  align-items: center;
  margin-right: 15px;
`

const Q = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  padding: 8px;
`

const QuestionContent = styled.div`
  display: flex;
  flex-flow: column wrap;
`

const QuestionCard = styled.div`
  width: 500px;
  display: flex;
  margin-bottom: 30px;
  min-height: 200px;
  align-items: flex-start;
`

const FAQ = () => (
  <Wrapper>
    <Heading type="h2" color="#11adf3">
      Frequently Asked Questions
    </Heading>
    <ContentWrapper>
      <QuestionCard>
        <QuestionSymbol>
          <Q>Q</Q>
        </QuestionSymbol>
        <QuestionContent>
          <Heading align="left" type="h4">
            WHO ARE THE AGENTS IN YOUR NETWORK?
          </Heading>
          <Paragraph size="15">
            We are a professional network of Real estate Agents, who collaborate to find the best deals for our clients.
            Our agents are in the top 15% of your market, who can provide you with the best customer service.
          </Paragraph>
        </QuestionContent>
      </QuestionCard>
      <QuestionCard>
        <QuestionSymbol>
          <Q>Q</Q>
        </QuestionSymbol>
        <QuestionContent>
          <Heading align="left" type="h4">
            WHY SHOULD I USE YOUR SERVICE INSTEAD OF JUST A GOOGLE SEARCH?
          </Heading>
          <Paragraph size="15">
            We use data of past performance and real current activity for each agent within our network to find you an
            agent that will best serve your needs. We will do the vetting and short-listing so you select the one you
            are comfortable with. No need to search through a series of a google results to find someone that may not
            even return your call.
          </Paragraph>
        </QuestionContent>
      </QuestionCard>
      <QuestionCard>
        <QuestionSymbol>
          <Q>Q</Q>
        </QuestionSymbol>
        <QuestionContent>
          <Heading align="left" type="h4">
            WHAT IF I KNOW EXACTLY WHAT PROPERTY I WANT & JUST NEED SOMEONE TO PLACE AN OFFER?
          </Heading>
          <Paragraph size="15">
            You can mention that while providing your information! We'll find an agent who is a strong negotiator and is
            willing to give you a higher than market rebate for their limited involvement in the deal.
          </Paragraph>
        </QuestionContent>
      </QuestionCard>
      <QuestionCard>
        <QuestionSymbol>
          <Q>Q</Q>
        </QuestionSymbol>
        <QuestionContent>
          <Heading align="left" type="h4">
            HOW WILL YOU IDENTIFY THE RIGHT AGENTS FOR ME?
          </Heading>
          <Paragraph size="15">
            Our expert client representatives will personally speak with you and carefully match your needs against the
            expertise of our agents.
          </Paragraph>
        </QuestionContent>
      </QuestionCard>
      <QuestionCard>
        <QuestionSymbol>
          <Q>Q</Q>
        </QuestionSymbol>
        <QuestionContent>
          <Heading align="left" type="h4">
            CAN I WORK WITH MULTIPLE AGENTS AT THE SAME TIME?
          </Heading>
          <Paragraph size="15">
            We recommend not to do that to avoid wasting your time and that of the agent's. You are best positioned in a
            purchase or sale if you are exclusively working with one agent who is committed to finding you the right
            deal.
          </Paragraph>
        </QuestionContent>
      </QuestionCard>
      <QuestionCard>
        <QuestionSymbol>
          <Q>Q</Q>
        </QuestionSymbol>
        <QuestionContent>
          <Heading align="left" type="h4">
            DO I GET CHARGED ANYTHING FOR THIS?
          </Heading>
          <Paragraph size="15">No, our agent matching service is free of charge to you.</Paragraph>
        </QuestionContent>
      </QuestionCard>
    </ContentWrapper>
  </Wrapper>
)

export default FAQ
