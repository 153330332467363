import React from "react"
import styled, { css } from "styled-components"
import Textbox from "dumbComponents/common/UI/Textbox"

const Label = styled.label`
  display: flex;
  position: relative;
  flex: 1;
  margin-bottom: 15px;
  input:active,
  input:focus {
    border-bottom: 2px solid #11adf3;
    ::placeholder {
      color: #ccc !important;
    }

    :-ms-input-placeholder {
      color: #ccc !important;
    }

    ::-ms-input-placeholder {
      color: #ccc !important;
    }
    & ~ span {
      //font-size: 14px;
      bottom: 45px;
    }
  }

  ${props => props.isFilled
    && css`
      span {
        //font-size: 14px;
        bottom: 45px;
      }
    `}
`

const Span = styled.span`
  transition-duration: 0.2s;
  position: absolute;
  pointer-events: none;
  bottom: 45px;
  left: 0px;
  width: 100%;
  user-select: none;
  color: #3c4858;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
`

const StyledInput = styled.input`
  flex: 1;
  font-size: 16px;
  background: transparent;
  outline: none;
  padding: 5px 0px;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  transition-duration: 0.2s;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: transparent !important;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: transparent !important;
    font-weight: 300;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: transparent !important;
    font-weight: 300;
  }
`

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
`

const Optional = styled.span`
  color: #ccc;
  display: block;
  position: absolute;
  bottom: -20px !important;
  font-size: 12px;
  left: 0px;
`

const Input = (field) => {
  const {
    label,
    input: { value },
    meta,
    optional,
    optionalText = "Optional",
    type,
    autoFocus,
    placeholder,
  } = field
  return (
    <div>
      <Label isFilled={value}>
        <Textbox {...field.input} type={type} placeholder={placeholder} autoFocus={autoFocus} />
        <Span>{label}</Span>
      </Label>
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
      {!(meta.touched && meta.error) && optional && <Optional className="optional-text">{optionalText}</Optional>}
    </div>
  )
}

export default Input
