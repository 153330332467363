import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as HomeActions from "./actions"

const mapStateToProps = (state, props) => {
  const { homes } = state
  return {
    ...homes,
    ...props,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      saveGoal: HomeActions.saveGoalAction,
      saveCampaign: HomeActions.saveCampaignAction,
      saveSpend: HomeActions.lookingToSpendAction,
      savePropertyTypes: HomeActions.selectPropertyTypesAction,
      saveSellerPropertyTypes: HomeActions.saveSellerPropertyTypesAction,
      saveTimeline: HomeActions.saveTimelineAction,
      savePropertyAddress: HomeActions.savePropertyAddressAction,
      submitLead: HomeActions.submitLeadAction.request,
      clearErrorLog: HomeActions.clearErrorMessageAction,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
