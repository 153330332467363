import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import { MOBILE_BREAK_POINT } from "shared/consts"
import Container from "container/Home"
import SideBar from "./SideBar"

const Wrapper = styled.div`
  background: url(//d2fedz0by71ckz.cloudfront.net/images/radius-homes-hero.jpg) no-repeat 50%;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
`

const SelectableBox = styled.div`
  width: 375px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  text-align: center;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 250px;
  }
  &:hover {
    background: #11adf3;
    color: #ffffff;
    cursor: pointer;
    p {
      color: #ffffff;
    }
  }
`

const StyledParagraph = styled.p`
  color: #11adf3;
  margin-bottom: 0;
`

const Timeline = (props) => {
  const {
    saveTimeline,
    history: { push },
    match: {
      params: { goal },
    },
    client,
    requirement,
    buyingLocation,
    sellerLocation,
    estimateText,
    timelineDisplay,
  } = props
  const setTimeline = (timeline, textToDisplay) => {
    if (goal === "buy") {
      saveTimeline({ timeline, textToDisplay })
    } else if (goal === "sell") {
      saveTimeline({ seller_timeline: timeline, textToDisplay })
    } else if (goal === "both") {
      saveTimeline({ timeline, seller_timeline: timeline, textToDisplay })
    }
    push(`/ads/${goal}/lead-info`)
  }
  return (
    <Wrapper>
      <Layout>
        <SideBar
          activePage={goal === "sell" ? 3 : 4}
          client={client}
          requirement={requirement}
          buyingLocation={buyingLocation}
          sellerLocation={sellerLocation}
          estimateText={estimateText}
          timelineDisplay={timelineDisplay}
          goal={goal}
        />
        <Content>
          {goal !== "sell" ? (
            <Heading margin="40px auto">How soon are you looking to buy?</Heading>
          ) : (
            <Heading margin="40px auto">How soon are you looking to sell?</Heading>
          )}
          <SelectableBox onClick={() => setTimeline(0, "ASAP")}>
            <StyledParagraph color="#11adf3">ASAP</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => setTimeline(30, "1-3 months")}>
            <StyledParagraph color="#11adf3">1-3 months</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => setTimeline(90, "3-6 months")}>
            <StyledParagraph color="#11adf3">3-6 months</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => setTimeline(180, "6-12 months")}>
            <StyledParagraph color="#11adf3">6-12 months</StyledParagraph>
          </SelectableBox>
          <SelectableBox onClick={() => setTimeline(360, "12+ months")}>
            <StyledParagraph color="#11adf3">12+ months</StyledParagraph>
          </SelectableBox>
        </Content>
      </Layout>
    </Wrapper>
  )
}

export default Container(Timeline)
