import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"

const Wrapper = styled.div`
  margin: 80px auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 40px 0;
`

const Anchor = styled.a`
  font-size: 22px;
  color: #11adf3;
  text-decoration: underline;
`

export const FurtherQuestions = () => (
  <Wrapper>
    <Heading type="h2" color="#11adf3">
      Still have questions? Get in touch
    </Heading>
    <ContentWrapper>
      <img src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/email%402x.png" height="46" alt="Email" />
      <Paragraph size="18" m="15px" mb="5" fontWeight="400">EMAIL</Paragraph>
      <Anchor href="mailto:homes@radiusagent.com">homes@radiusagent.com</Anchor>
    </ContentWrapper>
  </Wrapper>
)

export default FurtherQuestions
