import React, { Component } from "react"
import styled, { css } from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import colors from "library/colors"
import icons from "library/icons/icons"
import { MOBILE_BREAK_POINT } from "shared/consts"
import GooglePlaces from "dumbComponents/common/UI/GooglePlaces"
import IconInput from "dumbComponents/common/UI/IconInput"
import Button from "dumbComponents/common/UI/Button"
import Container from "container/Home"
import Steps from "./Steps"
import TopAgents from "./TopAgents"
import ChooseAgents from "./ChooseAgents"
import FAQ from "./FAQ"
import FurtherQuestions from "./FurtherQuestions"
import Footer from "./Footer"

const Wrapper = styled.div`
  background: url(//d2fedz0by71ckz.cloudfront.net/images/radius-homes-hero.jpg) no-repeat 50%;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
`
const Layout = styled.div`
  display: flex;
  flex-direction: row;
`
const SideBar = styled.div`
  width: 115px;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 50px;
  }
`

const LocationWrapper = styled.div`
  width: 400px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  animation: fade-up 0.4s ease-out forwards;
  @keyframes fade-up {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 200px;
    display: block;
    height: 35px;
  }
`

const BothWrapper = styled.div`
  width: 400px;
  height: 35px;
  display: flex;
  flex-flow: row wrap;
  animation: fade-up 0.4s ease-out forwards;
  @keyframes fade-up {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 200px;
    display: block;
    height: 35px;
  }
`

const CloseIcon = styled.i`
  font-size: 10px;
  font-weight: 300;
  color: ${colors.primaryInactiveColor};
  margin: 0 2px 0 6px;
`

const LocationTag = styled.div`
  margin: 0;
  cursor: pointer;
  line-height: 17px;
  width: 100%;
  display: flex;
  align-items: center;
`

const SearchIcon = styled.i`
  height: 40px;
  width: 40px;
  background-color: ${colors.primaryColor};
  color: white;
  font-size: 20px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: none;

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 70px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:before {
    position: relative;
    top: 9px;
    left: 10px;
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 580px;
  margin-top: 18%;
  animation: fade-up 0.4s ease-out forwards;
  @keyframes fade-up {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: auto;
  }
`

const Tabs = styled.ul`
  display: flex;
  padding: 0px;
  margin: 20px 0;
  margin-top: 15px;
  position: relative;
`
const Tab = styled.li`
  padding: 10px 0;
  margin-right: 20px;
  position: relative;
  top: 3px;
  ${props => props.isActive
    && css`
      border-bottom: ${colors.primaryColor} solid 4px;
    `}
`

const Anchor = styled.a`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #354052;
  text-decoration: none;
`

const Label = styled.label`
  font-size: 14px;
  color: #3c4858;
  line-height: 1.71;
`

class Home extends Component {
  state = {
    goal: "buy",
    buyTextboxValue: "",
    sellTextboxValue: "",
    location: {},
    sellerLocation: {},
  }

  changeGoal = (tab) => {
    this.setState({ goal: tab })
  }

  handleBuyTextBoxChange = (buyTextboxValue) => {
    this.setState({
      buyTextboxValue,
    })
  }

  handleSellTextBoxChange = (sellTextboxValue) => {
    this.setState({
      sellTextboxValue,
    })
  }

  handleBuyLocation = (location) => {
    this.setState({
      buyTextboxValue: `${location.city}, ${location.state}`,
      location: {
        ...this.state.location,
        city: location.city,
        state: location.state,
        country: location.country,
        c_locality_latitude: location.lat,
        c_locality_longitude: location.lng,
      },
    })
  }

  handleSellLocation = (location) => {
    this.setState({
      sellTextboxValue: `${location.city}, ${location.state}`,
      sellerLocation: {
        ...this.state.sellerLocation,
        city: location.city,
        state: location.state,
        country: location.country,
        c_locality_latitude: location.lat,
        c_locality_longitude: location.lng,
      },
    })
  }

  saveInfo = () => {
    const { goal, location, sellerLocation } = this.state
    const {
      saveGoal,
      history: { push },
    } = this.props
    if (goal === "buy") {
      saveGoal({ goal, location, sellerLocation: false })
    } else if (goal === "sell") {
      saveGoal({ goal, location: false, sellerLocation })
    } else {
      saveGoal({ goal, location, sellerLocation })
    }

    push(`/ads/${goal}/property-types`)
  }

  componentDidMount() {
    this.autoCompleteRef = React.createRef()
    const { saveCampaign } = this.props
    saveCampaign({ campaign: window.location.href })
  }

  render() {
    const {
      goal, buyTextboxValue, sellTextboxValue, location,
    } = this.state
    return (
      <React.Fragment>
        <Wrapper>
          <Layout>
            <SideBar />
            <Content>
              <Paragraph>I want to…</Paragraph>
              <Tabs>
                <Tab isActive={goal === "buy"}>
                  <Anchor href="javascript:void(0);" onClick={() => this.changeGoal("buy")}>
                    Buy a home
                  </Anchor>
                </Tab>
                <Tab isActive={goal === "sell"}>
                  <Anchor href="javascript:void(0);" onClick={() => this.changeGoal("sell")}>
                    Sell a home
                  </Anchor>
                </Tab>
                <Tab isActive={goal === "both"}>
                  <Anchor href="javascript:void(0);" onClick={() => this.changeGoal("both")}>
                    Both
                  </Anchor>
                </Tab>
              </Tabs>
              {goal === "buy" && (
                <Heading type="h1" align="left" lineHeight={0.93} margin="10px 0">
                  Get matched with the perfect agent to find your dream home
                </Heading>
              )}
              {goal === "sell" && (
                <Heading type="h1" align="left" lineHeight={0.93} margin="10px 0">
                  Get matched with the best listing agent for you
                </Heading>
              )}
              {goal === "both" && (
                <Heading type="h1" align="left" lineHeight={0.93} margin="10px 0">
                  Find the perfect agent to help you buy and sell
                </Heading>
              )}
              {goal === "buy" && (
                <LocationWrapper>
                  <GooglePlaces
                    onLocationSelect={this.handleBuyLocation}
                    addressTextBoxValue={buyTextboxValue}
                    handleAddressTextBoxChange={this.handleBuyTextBoxChange}
                    forwardedRef={this.autoCompleteRef}
                    placeholder="Search for city"
                    id="google-wrapper-buy"
                  />
                  <Button onClick={this.saveInfo}>Find an Agent</Button>
                </LocationWrapper>
              )}
              {goal === "sell" && (
                <LocationWrapper>
                  <GooglePlaces
                    onLocationSelect={this.handleSellLocation}
                    addressTextBoxValue={sellTextboxValue}
                    handleAddressTextBoxChange={this.handleSellTextBoxChange}
                    forwardedRef={this.autoCompleteRef}
                    placeholder="Search for city"
                    id="google-wrapper-sell"
                  />
                  <Button onClick={this.saveInfo}>Find an Agent</Button>
                </LocationWrapper>
              )}
              {goal === "both" && (
                <BothWrapper>
                  <Label>Buying in</Label>
                  <GooglePlaces
                    onLocationSelect={this.handleBuyLocation}
                    addressTextBoxValue={buyTextboxValue}
                    handleAddressTextBoxChange={this.handleBuyTextBoxChange}
                    forwardedRef={this.autoCompleteRef}
                    placeholder="Search for city"
                    id="google-wrapper-both-buy"
                  />
                  <Label>Selling in</Label>
                  <GooglePlaces
                    onLocationSelect={this.handleSellLocation}
                    addressTextBoxValue={sellTextboxValue}
                    handleAddressTextBoxChange={this.handleSellTextBoxChange}
                    forwardedRef={this.autoCompleteRef}
                    placeholder="Search for city"
                    id="google-wrapper-both-sell"
                  />
                  <Button onClick={this.saveInfo} margin="10px 0px 0px 0px">
                    Find an Agent
                  </Button>
                </BothWrapper>
              )}
            </Content>
          </Layout>
        </Wrapper>
        <div className="container">
          <Steps />
          <TopAgents />
          <ChooseAgents />
          <FAQ />
          <FurtherQuestions />
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default Container(Home)
