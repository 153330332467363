import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import LogoSvg from "images/svg/logo.svg"

const Nav = styled.nav`
  width: 100%;
  background-color: #ffffff;
  padding-left: 30px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: absolute;
  @media (max-width: 720px) {
    justify-content: center;
  }
`

const Logo = styled.img`
  display: inline-block;
  height: 28px;
`

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  @media (max-width: 720px) {
    display: none;
  }
`

const NavItem = styled.li`
  &:hover {
    background: #ffffff;
    a {
      color: #354052;
    }
  }

  a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: #354052;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
  }
`

const Navbar = () => (
  <Nav>
    <Link to="/">
      <Logo src={LogoSvg} alt="Radius Homes" />
    </Link>
    <NavLinks>
      {/* <NavItem>
        <a href="https://blog.radiusagent.com">About</a>
      </NavItem> */}
      {/* <NavItem>
        <a href="https://blog.radiusagent.com" target="_blank">Blog</a>
      </NavItem> */}
      {/* <NavItem>
        <a href="https://blog.radiusagent.com">Contact Us</a>
      </NavItem> */}
    </NavLinks>
  </Nav>
)

export default Navbar
