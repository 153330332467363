// Helper Functions
function funkyStringSplit(val = "") {
  const s = val
  let i = s.length % 3
  const parts = i ? [s.substr(0, i)] : []
  for (; i < s.length; i += 3) {
    parts.push(s.substr(i, 3))
  }
  return parts
}
const numberPattern = /\d+/g

export const required = value => (value ? undefined : "* Required")

export const commanSeperatedNumberUS = (value = "") => {
  try {
    if (!value) {
      return value
    }
    value = String(value)
    let trimmedNum = value.match(numberPattern)
    trimmedNum = Array.isArray(trimmedNum) ? trimmedNum.join("") : trimmedNum
    const parts = funkyStringSplit(trimmedNum) || [value]
    return parts.join(",")
  } catch (e) {
    return value
  }
}
