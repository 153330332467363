import React from "react"
import { Route } from "react-router-dom"
import Navbar from "dumbComponents/common/Navbar"

const HeaderRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <div>
        <Navbar />
        <Component {...props} />
      </div>
    )}
  />
)

export default HeaderRoute
