import { createSignalAction, createActionCreator } from "shared/reduxUtils"

const BASE = "HOMES"

export const SAVE_GOAL = "SAVE_GOAL"
export const LOOKING_TO_SPEND = "LOOKING_TO_SPEND"
export const SELECT_PROPERTY_TYPES = "SELECT_PROPERTY_TYPES"
export const SAVE_SELLER_PROPERTY_TYPES = "SAVE_SELLER_PROPERTY_TYPES"
export const SAVE_TIMELINE = "SAVE_TIMELINE"
export const SAVE_CLIENT_INFO = "SAVE_CLIENT_INFO"
export const SAVE_PROPERTY_ADDRESS = "SAVE_PROPERTY_ADDRESS"
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE"
export const SAVE_CAMPAIGN = "SAVE_CAMPAIGN"

export const submitLeadAction = createSignalAction(BASE, "SUBMIT_LEAD")

export const saveGoalAction = createActionCreator(SAVE_GOAL)
export const lookingToSpendAction = createActionCreator(LOOKING_TO_SPEND)
export const selectPropertyTypesAction = createActionCreator(SELECT_PROPERTY_TYPES)
export const saveTimelineAction = createActionCreator(SAVE_TIMELINE)
export const saveSellerPropertyTypesAction = createActionCreator(SAVE_SELLER_PROPERTY_TYPES)
export const savePropertyAddressAction = createActionCreator(SAVE_PROPERTY_ADDRESS)
export const clearErrorMessageAction = createActionCreator(CLEAR_ERROR_MESSAGE)
export const saveCampaignAction = createActionCreator(SAVE_CAMPAIGN)
