import React from "react"
import styled from "styled-components"
import Button from "dumbComponents/common/UI/Button"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import { Input, LocationAutoComplete } from "dumbComponents/common/UI/Form"
import { reduxForm, Field } from "redux-form"
import Box from "dumbComponents/common/UI/Box"
import { getClientHash } from "services/Utils"
import { required } from "services/formUtils"
import Container from "container/Home"
import SideBar from "./SideBar"

const Wrapper = styled.div`
  background: url(//d2fedz0by71ckz.cloudfront.net/images/radius-homes-hero.jpg) no-repeat 50%;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
`

const StyledParagraph = styled.p`
  color: #11adf3;
  margin-bottom: 0;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 350px;
  ${props => props.disabled
    && css`
      opacity: 0.2;
      pointer-events: none;
    `}
`

const ErrorText = styled(Paragraph)`
  color: #ff0000;
`

class LeadInfo extends React.Component {
  state = {
    isFormValid: false,
  }

  handleAddressSelect = (location) => {
    console.log(location)
    this.setState({ address: location })
    const {
      savePropertyAddress,
      match: {
        params: { goal },
      },
    } = this.props
    const { address } = location
    savePropertyAddress({ address, lookingToBuy: goal === "both" })
  }

  handleFormSubmit = (clientObj, dispatch, form) => {
    const {
      history: { push },
      match: {
        params: { goal },
      },
      submitLead,
      client,
    } = this.props
    const payload = { ...clientObj }
    payload.agent_requirements = client.agent_requirements
    payload.referral_source = "trustle"
    payload.sales_stage = "Leads"
    payload.sources = ["trustle"]
    payload.types = goal === "sell" ? ["Seller"] : ["Buyer"]
    payload.hash = getClientHash()
    payload.campaign = client.campaign || undefined
    submitLead({ payload, push })
  }

  componentDidMount() {
    const { clearErrorLog } = this.props
    clearErrorLog()
  }

  render() {
    const {
      match: {
        params: { goal },
      },
      client,
      requirement,
      buyingLocation,
      sellerLocation,
      estimateText,
      timelineDisplay,
      handleSubmit,
      isSubmitting,
      errorMessage,
    } = this.props
    return (
      <Wrapper>
        <Layout>
          <SideBar
            client={client}
            requirement={requirement}
            buyingLocation={buyingLocation}
            sellerLocation={sellerLocation}
            estimateText={estimateText}
            timelineDisplay={timelineDisplay}
            goal={goal}
          />
          <Content>
            <Heading margin="40px auto">What is the best way to contact you?</Heading>
            <Form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <Box d="flex" fd="column" mt="20">
                {goal !== "buy" && (
                  <Box mb="20">
                    <Field
                      component={LocationAutoComplete}
                      name="location"
                      id="cities"
                      types={["address"]}
                      location={(addressVal) => {
                        this.handleAddressSelect(addressVal)
                      }}
                      label="Selling Address"
                      placeholder="Property Address"
                      loader={false}
                      validate={[required]}
                    />
                  </Box>
                )}
                <Box mb="20">
                  <Field
                    name="name"
                    component={Input}
                    type="text"
                    placeholder="Full Name"
                    label="Name"
                    validate={required}
                  />
                </Box>
                <Box d="flex" mb="40">
                  <Box flex="1">
                    <Field
                      name="emails[0].email"
                      component={Input}
                      type="email"
                      placeholder="Email Address"
                      label="Email"
                      validate={required}
                    />
                  </Box>
                </Box>
                <Box d="flex" mb="20">
                  <Box flex="1">
                    <Field
                      name="phones[0].phone"
                      component={Input}
                      type="text"
                      label="Phone number"
                      placeholder="Phone Number"
                      validate={required}
                    />
                  </Box>
                </Box>
                <Box d="flex" jc="center" ai="center">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Box>
              </Box>
            </Form>
            {errorMessage && (
              <Box d="flex" jc="center" ai="center">
                <ErrorText>{errorMessage}</ErrorText>
              </Box>
            )}
          </Content>
        </Layout>
      </Wrapper>
    )
  }
}

const LeadInfoForm = reduxForm({
  form: "LEAD_INFO",
  initialValues: { type: "Buyer" },
})(LeadInfo)

export default Container(LeadInfoForm)
