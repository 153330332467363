import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import CheckSuccessPng from "images/check-icon.png"
import { MOBILE_BREAK_POINT } from "shared/consts"

const Wrapper = styled.div`
  background: url(//d2fedz0by71ckz.cloudfront.net/images/radius-homes-hero.jpg) no-repeat 50%;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

const SuccessContainer = styled.div`
  background: #ffffff;
  width: 400px;
  height: 400px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  animation: fade-up 0.4s ease-out forwards;
  @keyframes fade-up {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 300px;
    height: 300px;
    p {
      text-align: center;
    }
  }
`

const Image = styled.img`
  height: 80px;
  width: 80px;
`

const Success = props => (
  <Wrapper>
    <Layout>
      <Content>
        <SuccessContainer>
          <Image src={CheckSuccessPng} />
          <Heading margin="10px">Congrats!</Heading>
          <Paragraph>Thank you for submitting your information!</Paragraph>
          <Paragraph>You will be contacted shortly.</Paragraph>
        </SuccessContainer>
      </Content>
    </Layout>
  </Wrapper>
)

export default Success
