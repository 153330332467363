import React from "react"
import styled from "styled-components"
import GoogleAutoComplete from "dumbComponents/common/UI/GoogleAutoComplete"

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  color: #3c4858;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.71;
  font-weight: 300;
`

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
`

const RenderLocationAutoComplete = (props) => {
  const {
    id,
    types,
    location,
    placeholder,
    loader,
    input: { onBlur, onFocus } = {},
    meta: { error, touched } = {},
  } = props
  return (
    <Wrapper onBlur={onBlur} onFocus={onFocus}>
      <Label>Selling Address</Label>
      <GoogleAutoComplete
        id={id}
        types={types}
        location={location}
        placeholder={placeholder}
        loader={loader}
        isTextbox
      />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  )
}

export default RenderLocationAutoComplete
