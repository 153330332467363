import React from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { MOBILE_BREAK_POINT } from "shared/consts"

const Wrapper = styled.div`
  padding-top: 60px;
  background: rgba(255, 255, 255, 0.45);
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    display: none;
  }
`

const TimelineItem = styled.div`
  margin-left: 40px;
  padding: 0 1em 3em;
  position: relative;
  color: #3c4858;
  border-left: 4px solid #3c4858;

  label {
    margin-top: 0;
    padding: 2px 10px;
    font-size: 14px;
    height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &::before {
    content: attr("20-07-1990");
    position: absolute;
    left: 2em;
    font-weight: bold;
    top: 1em;
    display: block;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 0.785rem;
  }

  &::after {
    width: 10px;
    height: 10px;
    display: block;
    top: 0em;
    position: absolute;
    left: -12px;
    border-radius: 10px;
    content: "";
    border: 10px solid #3c4858;
    background: white;
  }

  ${props => props.activePage
    && css`
      &:nth-child(${props.activePage}) {
        &::after {
          content: "\u25c9";
          width: 26px;
          height: 26px;
          background: #ffffff;
          border: none;
          border-radius: 50%;
          left: -16px;
          text-align: center;
          padding-left: 0px;
          padding-top: 0px;
          color: #11adf3;
          top: -5px;
          font-size: 30px;
        }
      }
    `}

  &:last-child {
    border-left: 4px solid transparent;
  }
`

const P = styled.p`
  font-size: 14px;
  margin-top: 10px;
  padding-left: 10px;
  small {
    display: block;
    font-size: 16px;
    font-weight: 500;
  }
`

const Label = styled.label`
  color: ${props => (props.isActive ? "#11adf3" : "#3c4858")};
`

const Anchor = styled(Link)`
  text-decoration: none;
  &:hover {
    label {
      color: #11adf3;
      cursor: pointer;
    }
  }
`

const SideBar = ({
  activePage,
  goal,
  client: {
    agent_requirements: { property },
  },
  requirement: { types },
  buyingLocation,
  sellerLocation,
  estimateText,
  timelineDisplay,
}) => (
  <Wrapper>
    <TimelineItem activePage={activePage}>
      <Anchor to="/ads">
        <Label isActive={activePage === 1}>Step 1: Location</Label>
      </Anchor>

      {buyingLocation && (
        <P>
          Buying in
          <small>{buyingLocation.city}</small>
        </P>
      )}
      {sellerLocation && (goal === "sell" || goal === "both") && (
        <P>
          Selling in
          <small>{sellerLocation.city}</small>
        </P>
      )}
    </TimelineItem>
    <TimelineItem activePage={activePage}>
      <Anchor to={`/ads/${goal}/property-types`}>
        <Label isActive={activePage === 2}>Step 2: Property Type</Label>
      </Anchor>
      {activePage !== 2 && (
        <P>
          Looking for
          {goal !== "sell" && types && <small>{`${types[0]} ${types.length > 1 ? `& ${types.length - 1} more` : ""}`} </small>}
          {goal === "sell" && property && <small>{property.property_type}</small>}
        </P>
      )}
    </TimelineItem>
    {goal !== "sell" && (
      <TimelineItem activePage={activePage}>
        <Anchor to={`/ads/${goal}/looking-to-spend`}>
          <Label isActive={activePage === 3}>Step 3: Budget</Label>
        </Anchor>
        {estimateText ? (
          <P>
            Looking to spend
            <small>{estimateText}</small>
          </P>
        ) : null}
      </TimelineItem>
    )}
    <TimelineItem activePage={activePage}>
    <Anchor to={`/ads/${goal}/timeline`}>
      <Label isActive={activePage === (goal === "sell" ? 3 : 4)}>{`Step ${goal === "sell" ? 3 : 4}: Timeline`}</Label>
      </Anchor>
      {timelineDisplay ? (
        <P>
          Looking to buy in
          <small>{timelineDisplay}</small>
        </P>
      ) : null}
    </TimelineItem>
  </Wrapper>
)

export default SideBar
