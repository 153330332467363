import { combineReducers } from "redux"
import { routerReducer as routing } from "react-router-redux"
import { reducer as formReducer } from "redux-form"
import HomeReducer from "container/Home/reducer"

const rootReducer = combineReducers({
  form: formReducer,
  routing,
  homes: HomeReducer,
})

export default rootReducer

