import React from "react"
import styled from "styled-components"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"

const Wrapper = styled.div`
  margin: 80px auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 40px 0;
`

const InformationCard = styled.div`
  width: 380px;
  text-align: center;
`

const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: rgba(238, 126, 104, 0.16);
  font-size: 36px;
  color: #ee7e68;
  margin: 20px auto;
  border-radius: 50%;
`

export const Steps = () => (
  <Wrapper>
    <Heading type="h2" color="#11adf3">
      Find the right agent in 3 steps
      <ContentWrapper>
        <InformationCard>
          <StepCircle>1</StepCircle>
          <Paragraph fontWeight="500" align="center">
            Tell us what you're looking for
          </Paragraph>
          <Paragraph align="center">
            Whether it’s a hands on representative to be there every step of the way, or just a consultation.
          </Paragraph>
        </InformationCard>
        <InformationCard>
          <StepCircle>2</StepCircle>
          <Paragraph fontWeight="500" align="center">
            We match you with the best agents
          </Paragraph>
          <Paragraph align="center">
            Based on your unique needs and situation, we’ll suggest 3 agents within 24 hours.
          </Paragraph>
        </InformationCard>
        <InformationCard>
          <StepCircle>3</StepCircle>
          <Paragraph fontWeight="500" align="center">
            You pick the perfect one
          </Paragraph>
          <Paragraph align="center">
            Review profiles, interview these agents & choose the one you want to work with!
          </Paragraph>
        </InformationCard>
      </ContentWrapper>
    </Heading>
  </Wrapper>
)

export default Steps
