import * as HomeActions from "./actions"

const initState = {
  requirement: {
    locations: [],
    types: ["Single-family home"],
  },
  client: {
    agent_requirements: {
      goal: "buy",
    },
  },
  estimateText: false,
  timelineDisplay: false,
  buyingLocation: false,
  sellerLocation: false,
  isSubmitting: false,
  errorMessage: false,
}

export default function homeReducer(state = initState, action) {
  switch (action.type) {
    case HomeActions.submitLeadAction.REQUEST: {
      const { goal, location, sellerLocation } = action.data
      return {
        ...state,
        isSubmitting: true,
        errorMessage: false,
      }
    }

    case HomeActions.submitLeadAction.SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        errorMessage: false,
      }
    }

    case HomeActions.submitLeadAction.FAILURE: {
      const { errorMessage } = action.data
      console.log(errorMessage)
      return {
        ...state,
        isSubmitting: false,
        errorMessage,
      }
    }

    case HomeActions.SAVE_GOAL: {
      const { goal, location, sellerLocation } = action.data
      return {
        ...state,
        requirement: {
          ...state.requirement,
          locations: [location],
          max_budget: null,
          min_budget: null,
        },
        client: {
          ...state.client,
          agent_requirements: {
            ...state.client.agent_requirements,
            goal,
          },
        },
        buyingLocation: location,
        sellerLocation,
      }
    }

    case HomeActions.SAVE_CAMPAIGN: {
      const { campaign } = action.data
      return {
        ...state,
        client: {
          ...state.client,
          campaign,
        },
      }
    }
    case HomeActions.LOOKING_TO_SPEND: {
      const { max_budget, min_budget, textToDisplay } = action.data
      return {
        ...state,
        estimateText: textToDisplay,
        requirement: {
          ...state.requirement,
          max_budget,
          min_budget,
        },
      }
    }
    case HomeActions.SELECT_PROPERTY_TYPES: {
      const { types } = action.data
      return {
        ...state,
        requirement: {
          ...state.requirement,
          types,
        },
      }
    }
    case HomeActions.SAVE_TIMELINE: {
      const { timeline, seller_timeline, textToDisplay } = action.data
      return {
        ...state,
        timelineDisplay: textToDisplay,
        client: {
          ...state.client,
          agent_requirements: {
            ...state.client.agent_requirements,
            timeline,
            seller_timeline: seller_timeline || null,
          },
        },
      }
    }
    case HomeActions.SAVE_SELLER_PROPERTY_TYPES: {
      const { property_type } = action.data
      return {
        ...state,
        client: {
          ...state.client,
          agent_requirements: {
            ...state.client.agent_requirements,
            property: {
              ...state.client.agent_requirements.property,
              property_type,
            },
          },
        },
      }
    }
    case HomeActions.SAVE_PROPERTY_ADDRESS: {
      const { address, lookingToBuy } = action.data
      return {
        ...state,
        client: {
          ...state.client,
          agent_requirements: {
            ...state.client.agent_requirements,
            property: {
              ...state.client.agent_requirements.property,
              address,
              lookingToBuy,
            },
          },
        },
      }
    }
    case HomeActions.CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
