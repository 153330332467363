import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Layout from "dumbComponents/common/Layout"
import HeaderRoute from "hoc/HeaderRoute"
import Home from "dumbComponents/GAds"
import LookingToSpend from "dumbComponents/GAds/LookingToSpend"
import PropertyTypes from "dumbComponents/GAds/PropertyTypes"
import Timeline from "dumbComponents/GAds/Timeline"
import LeadInfo from "dumbComponents/GAds/LeadInfo"
import Success from "dumbComponents/GAds/Success"

const Router = () => (
  <Layout>
    <Switch>
      <HeaderRoute path="/ads" component={Home} exact />
      <HeaderRoute path="/ads/find-an-agent" component={Home} />
      <HeaderRoute path="/ads/find-an-agent-1" component={Home} />
      <HeaderRoute path="/ads/find-an-agent-2" component={Home} />
      <HeaderRoute path="/ads/find-an-agent-3" component={Home} />
      <HeaderRoute path="/ads/find-an-agent-4" component={Home} />
      <HeaderRoute path="/ads/find-an-agent-5" component={Home} />
      <HeaderRoute path="/ads/:goal/looking-to-spend" component={LookingToSpend} />
      <HeaderRoute path="/ads/:goal/property-types" component={PropertyTypes} />
      <HeaderRoute path="/ads/:goal/timeline" component={Timeline} />
      <HeaderRoute path="/ads/:goal/lead-info" component={LeadInfo} />
      <HeaderRoute path="/ads/success" component={Success} />
      <Redirect to="/ads" />
    </Switch>
  </Layout>
)

export default Router
