import React from "react"
import styled from "styled-components"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Button from "dumbComponents/common/UI/Button"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-top: solid 1px #cacacb;
`

const Brand = styled.div`
  display: flex;
  margin: 10px 0;
`

const BrandContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-left: 15px;
`

const Small = styled.div`
  color: #737577;
  font-size: 10px;
`

const SocialMediaContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Anchor = styled.a`
  text-decoration: none;
  margin: 10px 0;
  display: flex;
  align-items: center;
`

const Footer = () => (
  <Wrapper>
    <Brand>
      <img src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/fheo60.png" alt="brand" />
      <BrandContent>
        <Paragraph size="16" color="#737577">
          Powered By
          {" "}
          <a href="https://www.radiusagent.com" style={{ color: "#11adf3", textDecoration: "none" }} target="_blank">
            Radius Agent
          </a>
          {" "}
          © 2019
        </Paragraph>
        <Small>Professional network for Real Estate Agents & Brokers</Small>
      </BrandContent>
    </Brand>
    <Anchor href="https://www.radiusagent.com" target="_blank">
      <Button bsStyle="secondary" borderRadius="4px" height="35px" width="auto">
        Join Radius
      </Button>
    </Anchor>
    <Anchor href="https://radius.homes/terms-and-conditions" target="_blank">
      <Paragraph size="17" fontWeight="400">
        Terms of Use
      </Paragraph>
    </Anchor>
    <Anchor href="https://radius.homes/privacy-policy" target="_blank">
      <Paragraph size="17" fontWeight="400">
        Privacy policy
      </Paragraph>
    </Anchor>
    <SocialMediaContainer>
      <a href="https://twitter.com/radiusagent" target="_blank">
        <img src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/twitter+copy.png" alt="Twitter" />
      </a>
      <a href="https://www.instagram.com/radius_agent" target="_blank">
        <img src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/Instagram.png" alt="Instagram" />
      </a>
      <a href="https://www.facebook.com/radiusagent" target="_blank">
        <img src="https://s3.amazonaws.com/icons.agentdesks.com/mail_api/facebook.png" alt="Facebook" />
      </a>
    </SocialMediaContainer>
  </Wrapper>
)

export default Footer
